<template>
  <router-view />
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  components: {
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
