import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import {
  Field, Table, Checkbox, Input, Pagination,
} from 'buefy'
import { MonthPicker } from 'vue-month-picker'
import PrimeVue from 'primevue/config'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
// import 'buefy/dist/buefy.css'
// Global Components
import './global-components'
// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/tour'
import '@/libs/vue-gapi'
import ServerHub from '@/libs/server-hub'
import 'primeicons/primeicons.css'

import BadgeDirective from 'primevue/badgedirective'
import Ripple from 'primevue/ripple'
import StyleClass from 'primevue/styleclass'
import Tooltip from 'primevue/tooltip'
// Подключаем стили
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/lara-light-indigo/theme.css'// или ваш предпочтительный стиль
import 'primevue/resources/primevue.min.css'

// Axios Mock Adapter
import '@/@fake-db/db'

Vue.directive('tooltip', Tooltip)
Vue.directive('badge', BadgeDirective)
Vue.directive('ripple', Ripple)
Vue.directive('styleclass', StyleClass)

Vue.use(MonthPicker)
Vue.use(PrimeVue)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(Field)
Vue.use(Pagination)
Vue.use(Input)
Vue.use(Table)
Vue.use(Checkbox)

Vue.use(ServerHub)
// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
