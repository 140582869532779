<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <!--<scroll-to-top v-if="enableScrollToTop" />-->
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

import { useWindowSize, useCssVar } from '@vueuse/core'

import { Russian } from 'flatpickr/dist/l10n/ru'
import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const NoLayout = () => import('@/layouts/nolayout/NoLayout.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    NoLayout,

    ScrollToTop,
  },
  data() {
    return {
      message: null,
    }
  },
  computed: {
    layout() {
      console.log(this.$route, 'this.$routethis.$routethis.$routethis.$routethis.$routethis.$routethis.$routethis.$route')
      if (this.$route.path.includes('/dashboard/public/analytics')) {
        return 'no-layout'
      }

      if (this.$route.meta.layout === 'full') return 'layout-full'

      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  created() {
    // Listen to answer changes from SignalR event
    this.$serverHub.$on('prepare-data', this.onMessageReceived)
    this.$serverHub.$on('confirm', this.confirmReceived)
  },
  beforeDestroy() {
    this.$serverHub.$off('prepare-data', this.onMessageReceived)
    this.$serverHub.$off('confirm', this.confirmReceived)
  },
  beforeCreate() {
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  methods: {
    confirmReceived({ text }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Подтверждение завершено успешно',
          icon: 'MailIcon',
          variant: 'success',
          text,
        },
      })
    },
    onMessageReceived({ text, projectId, isEnded }) {
      if (isEnded) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Загрузчик данных',
            icon: 'DownloadCloudIcon',
            variant: 'success',
            text,
          },
        })
      }
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
